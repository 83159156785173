import { useEffect, useState, useCallback, useRef } from "react";
import { ollamaurl, ollamamodel } from "./config";
import useStore from "./store";

function useIsTabInUnfocused() {
  const [isBackground, setIsBackground] = useState(document.hidden);
  useEffect(() => {
    function onFocus() {
      setIsBackground(false);
    }
    function onBlur() {
      setIsBackground(true);
    }
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [setIsBackground]);
  return isBackground;
}

export function useAutoRefresh(cb, timeout, timeoutBackground) {
  if (!timeoutBackground) {
    timeoutBackground = timeout;
  }

  const isUnfocused = useIsTabInUnfocused();

  useEffect(() => {
    cb();
    const tm = isUnfocused ? timeoutBackground : timeout;
    const interval = setInterval(cb, tm);
    return () => clearInterval(interval);
  }, [cb, timeout, timeoutBackground, isUnfocused]);
}

// check if ollama is available. If so, flag it in the store.
export function useOllamaAvailabilityCheck() {
  const setOllamaAvailable = useStore((state => state.setOllamaAvailable));
  useEffect(() => {
    async function check() {
      try {
        const resp = await fetch(`${ollamaurl}tags`);
        const modelData = await resp.json();
        if (modelData && modelData.models && modelData.models.some(m => m.name === ollamamodel)) {
          setOllamaAvailable();
        }
      }
      catch { }
    }
    check();
  }, [setOllamaAvailable]);
}

export function useIsOllamaAvailableResult() {
  return useStore(state => state.ollamaAvailable);
}

export function useOllamaAutoSummaryState() {
  const isOllamaAutoSummaryEnabled = useStore(state => state.ollamaAutoSummary);
  const setOllamaAutoSummaryEnabled = useStore(state => state.setOllamaAutoSummary);
  const toggleOllamaAutoSummaryEnabled = useCallback(
    () => {
      setOllamaAutoSummaryEnabled(!isOllamaAutoSummaryEnabled);
    },
    [setOllamaAutoSummaryEnabled, isOllamaAutoSummaryEnabled]
  );
  return {
    isOllamaAutoSummaryEnabled,
    setOllamaAutoSummaryEnabled,
    toggleOllamaAutoSummaryEnabled
  };
}

export function useOllamaHeadlineSummary() {
  const [summary, setSummary] = useState('');
  const [summaryRequested, setSummaryRequested] = useState(false);
  const isOllamaAvailable = useIsOllamaAvailableResult();

  const requestSummary = useCallback((title, feedName, headline) => {
    setSummaryRequested(true);

    async function doit() {
      const prompt = `
You are a commentator. Your task is to write a summary of a text. 

It will include the source where the text was posted, title of it, and the text.

# Source:
${feedName}

# Title:
${title}

# Text:
${headline}

# Instructions:
## Summarize:
In clear and concise language, summarize the key points and themes presented in the text.

Your summary should be nicely formatted into paragraphs.

Only include information present in the article text. Do NOT include facts you know yourself.

Use plain text formatting and only include the summarized text and nothing else.

## Summary:
`.trim();

      const response = await fetch(`${ollamaurl}generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          model: ollamamodel,
          prompt,
          options: {
            temperature: 0.1
          },
          stream: true
        })
      });

      let summaryText = '';
      const decoder = new TextDecoder("utf-8");
      const reader = response.body.getReader();
      
      while (true) {
        const { done, value } = await reader.read();
        let unparsedBuffer = '';
        if (value) {
          const text = decoder.decode(value);
          try {
            const parsedLine = JSON.parse(unparsedBuffer + text);
            summaryText += summaryText === '' ? parsedLine.response.trimStart() : parsedLine.response;
            setSummary(summaryText);
            unparsedBuffer = '';
          } catch(e) {
            unparsedBuffer += text;
          }
        }
        if (done) {
          break;
        }
      }
    }
    doit();
  }, [setSummary]);

  return {
    isOllamaAvailable,
    summary,
    requestSummary,
    summaryRequested
  };
}

async function getTimestamp() {
  const path = '/timestamp.txt';
  const req = await fetch(path, {cache: "no-store"});
  const timestamp = await req.text();
  return timestamp;
}

export function useAppUpdateChecker() {
  const initialTimestampRef = useRef(undefined);
  useAutoRefresh(async () => {
    try {
      const timestamp = await getTimestamp();
      if (!initialTimestampRef.current) {
        initialTimestampRef.current = timestamp;
      }
      else if (initialTimestampRef.current !== timestamp) {
        console.log("Update detected! ", initialTimestampRef.current, timestamp);
        window.location.reload();
      }
    }
    catch (e) {
      console.log("Error updating timestamp.txt", e);
    }
  }, 60000, 60000);
}
