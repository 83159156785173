import * as React from "react";
import { Waypoint } from "react-waypoint";
import { useCallback, useState } from "react";

export default function LazyVideo({ children, ...rest }) {
  const [visible, setVisible] = useState(false);
  const onEnter = useCallback(() => {
    setVisible(true);
  }, []);

  return visible ? (
    <video {...rest}>{children}</video>
  ) : (
    <>
      <Waypoint topOffset={-1000} onEnter={onEnter} />
      <div className="video-clip" />
    </>
  );
}
