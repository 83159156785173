export default class ApiClient {
  constructor(apiBaseUrl, getApiKey, authError) {
    this.apiBaseUrl = apiBaseUrl;
    this.getApiKey = getApiKey;
    this.authError = authError;
  }

  endpoint(...parts) {
    const apiBaseUrl = this.apiBaseUrl;
    const apiKey = this.getApiKey();
    const urlSearchParams = new URLSearchParams();
    let body = undefined;
    let contentType = "application/json";
    let returnRawResponseObject = false;

    async function call(method) {
      const query = urlSearchParams.size ? `?${urlSearchParams.toString()}` : '';
      const endpoint = `/v1/${parts.join('/')}${query}`;

      const response = await fetch(`${apiBaseUrl}${endpoint}`, {
        headers: {
          "X-Auth-Token": apiKey,
          ...(!!body && { "Content-Type": contentType })
        },
        method,
        ...(!!body && { body }),
      });

      if (returnRawResponseObject) {
        return response;
      }

      if (response.ok) {
        try {
          return await response.json();
        }
        catch {
          // response.json() could fail if the reponse body is empty
          return {};
        }
      }
      else if (response.status === 401 || response.status === 403) {
        this.authError();
      }
      else {
        throw new Error(`[${method} ${endpoint}] failed with status code ${response.status}`, { cause: response });
      }
    }

    const builder = {
      body(obj) {
        body = JSON.stringify(obj);
        return builder;
      },

      bodyRaw(str) {
        body = str;
        return builder;
      },

      contentType(str) {
        contentType = str;
        return builder;
      },

      searchParams(obj) {
        for (const key of Object.keys(obj)) {
          urlSearchParams.append(key, obj[key]);
        }
        return builder;
      },

      returnRawResponseObject() {
        returnRawResponseObject = true;
        return builder;
      },

      async get() {
        return await call("GET");
      },
    
      async post() {
        return await call("POST");
      },
    
      async put() {
        return await call("PUT");
      },
    
      async del() {
        return await call("DELETE");
      },
    };

    return builder;
  }

  async discoverSubscriptions(
    url, 
    feedUsername = undefined, 
    feedPassword = undefined, 
    user_agent = undefined, 
    fetch_via_proxy = false,
  ) {
    return await this.endpoint("discover").body({ 
      url, 
      ...(feedUsername ?? { feedUsername }), 
      ...(feedPassword ?? { feedPassword }), 
      ...(user_agent ?? { user_agent }),
      ...(fetch_via_proxy ?? { fetch_via_proxy }),
    }).post();
  }

  async flushHistory() {
    return await this.endpoint("flush-history").put();
  }

  async getFeeds() {
    return await this.endpoint("feeds").get();
  }

  async getCategoryFeeds(category_id) {
    return await this.endpoint("categories", category_id, "feeds").get();
  }

  async getFeed(feed_id) {
    return await this.endpoint("feeds", feed_id).get();
  }
  
  async getFeedIconByFeedId(feed_id) {
    return await this.endpoint("feeds", feed_id, "icon").get();
  }

  async getFeedIconByIconId(icon_id) {
    return await this.endpoint("icons", icon_id).get();
  }

  async createFeed(
    feed_url, 
    category_id, 
    username = undefined, 
    password = undefined, 
    crawler = undefined,
    user_agent = undefined, 
    scraper_rules = undefined, 
    rewrite_rules = undefined, 
    blocklist_rules = undefined, 
    keeplist_rules = undefined, 
    disabled = undefined, 
    ignore_http_cache = undefined, 
    fetch_via_proxy = undefined
  ) {
    return await this.endpoint("feeds").body({
      feed_url, 
      category_id, 
      ...(username ?? { username }), 
      ...(password ?? { password }), 
      ...(crawler ?? { crawler }), 
      ...(user_agent ?? { user_agent }), 
      ...(scraper_rules ?? { scraper_rules }),
      ...(rewrite_rules ?? { rewrite_rules }),
      ...(blocklist_rules ?? { blocklist_rules }), 
      ...(keeplist_rules ?? { keeplist_rules }), 
      ...(disabled ?? { disabled }),
      ...(ignore_http_cache ?? { ignore_http_cache }),
      ...(fetch_via_proxy ?? { fetch_via_proxy }),
    }).post();
  }

  async updateFeed(
    feed_id,
    feed_url, 
    category_id, 
    username = undefined, 
    password = undefined, 
    crawler = undefined, 
    user_agent = undefined, 
    scraper_rules = undefined, 
    rewrite_rules = undefined, 
    blocklist_rules = undefined, 
    keeplist_rules = undefined, 
    disabled = undefined, 
    ignore_http_cache = undefined, 
    fetch_via_proxy = undefined
  ) {
    return await this.endpoint("feeds", feed_id).body({
      feed_url,
      category_id,
      ...(username ?? { username }), 
      ...(password ?? { password }), 
      ...(crawler ?? { crawler }), 
      ...(user_agent ?? { user_agent }), 
      ...(scraper_rules ?? { scraper_rules }),
      ...(rewrite_rules ?? { rewrite_rules }),
      ...(blocklist_rules ?? { blocklist_rules }), 
      ...(keeplist_rules ?? { keeplist_rules }), 
      ...(disabled ?? { disabled }),
      ...(ignore_http_cache ?? { ignore_http_cache }),
      ...(fetch_via_proxy ?? { fetch_via_proxy }),
    }).put();
  }

  async refreshFeed(feed_id) {
    return await this.endpoint("feeds", feed_id, "refresh").put();
  }

  async refreshAllFeeds() {
    return await this.endpoints("feeds", "refresh").put();
  }

  async removeFeed(feed_id) {
    return await this.endpoint("feeds", feed_id).del();
  }

  async getFeedEntry(feed_id, entry_id) {
    return await this.endpoint("feeds", feed_id, "entries", entry_id).get();
  }

  async getEntry(entry_id) {
    return await this.endpoint("entries", entry_id).get();
  }

  async updateEntry(entry_id, title = undefined, content = undefined) {
    return await this.endpoint("entries", entry_id).body({
      ...(title && { title }),
      ...(content && { content }),
    }).put();
  }

  async saveEntryToThirdPartyServices(entry_id) {
    return await this.endpoint("entries", entry_id, "save").post();
  }

  async fetchEntryContent(entry_id) {
    return await this.endpoint("entries", entry_id, "fetch-content").get();
  }

  async getCategoryEntries(
    category_id, 
    offset = undefined, 
    limit = undefined, 
    status = undefined,
    order = undefined,
    direction = undefined,
    before = undefined,
    after = undefined,
    published_before = undefined,
    published_after = undefined,
    changed_before = undefined,
    changed_after = undefined,
    before_entry_id = undefined,
    after_entry_id = undefined,
    starred = undefined,
    search = undefined,
    filter_category_id = undefined
  ) {
    return await this.endpoint("categories", category_id, "entries").searchParams({
      ...(offset && { offset }),
      ...(limit && { limit }),
      ...(status && { status }),
      ...(order && { order }),
      ...(direction && { direction }),
      ...(before && { before }),
      ...(after && { after }),
      ...(published_before && { published_before }),
      ...(published_after && { published_after }),
      ...(changed_before && { changed_before }),
      ...(changed_after && { changed_after }),
      ...(before_entry_id && { before_entry_id }),
      ...(after_entry_id && { after_entry_id }),
      ...(starred && { starred }),
      ...(search && { search }),
      ...(filter_category_id && { category_id: filter_category_id }),
    }).get();
  }

  async getFeedEntries(
    feed_id,
    offset = undefined, 
    limit = undefined, 
    status = undefined,
    order = undefined,
    direction = undefined,
    before = undefined,
    after = undefined,
    published_before = undefined,
    published_after = undefined,
    changed_before = undefined,
    changed_after = undefined,
    before_entry_id = undefined,
    after_entry_id = undefined,
    starred = undefined,
    search = undefined,
    filter_category_id = undefined
  ) {
    return await this.endpoint("feeds", feed_id, "entries").searchParams({
      ...(offset && { offset }),
      ...(limit && { limit }),
      ...(status && { status }),
      ...(order && { order }),
      ...(direction && { direction }),
      ...(before && { before }),
      ...(after && { after }),
      ...(published_before && { published_before }),
      ...(published_after && { published_after }),
      ...(changed_before && { changed_before }),
      ...(changed_after && { changed_after }),
      ...(before_entry_id && { before_entry_id }),
      ...(after_entry_id && { after_entry_id }),
      ...(starred && { starred }),
      ...(search && { search }),
      ...(filter_category_id && { category_id: filter_category_id }),
    }).get();
  }

  async markFeedEntriesAsRead(feed_id) {
    return await this.endpoint("feeds", feed_id, "mark-all-as-read").put();
  }

  async getEntries(
    offset = undefined, 
    limit = undefined, 
    status = undefined,
    order = undefined,
    direction = undefined,
    before = undefined,
    after = undefined,
    published_before = undefined,
    published_after = undefined,
    changed_before = undefined,
    changed_after = undefined,
    before_entry_id = undefined,
    after_entry_id = undefined,
    starred = undefined,
    search = undefined,
    filter_category_id = undefined
  ) {
    return await this.endpoint("entries").searchParams({
      ...(offset && { offset }),
      ...(limit && { limit }),
      ...(status && { status }),
      ...(order && { order }),
      ...(direction && { direction }),
      ...(before && { before }),
      ...(after && { after }),
      ...(published_before && { published_before }),
      ...(published_after && { published_after }),
      ...(changed_before && { changed_before }),
      ...(changed_after && { changed_after }),
      ...(before_entry_id && { before_entry_id }),
      ...(after_entry_id && { after_entry_id }),
      ...(starred && { starred }),
      ...(search && { search }),
      ...(filter_category_id && { category_id: filter_category_id }),
    }).get();
  }

  async updateEntries(entry_ids, status = "read") {
    return await this.endpoint("entries").body({
      entry_ids,
      status,
    }).put();
  }

  async toggleEntryBookmark(entry_id) {
    return await this.endpoint("entries", entry_id, "bookmark").put();
  }

  async getCategories() {
    return await this.endpoint("categories").get();
  }

  async createCategory(title) {
    return await this.endpoint("categories").body({ title }).post();
  }

  async updateCategory(category_id, title) {
    return await this.endpoint("categories", category_id).body({ title }).put();
  }

  async refreshCategoryFeeds(category_id) {
    return await this.endpoint("categories", category_id, "refresh").put();
  }

  async deleteCategory(category_id) {
    return await this.endpoint("categories", category_id).del();
  }

  async markCategoryEntriesAsRead(category_id) {
    return await this.endpoint("categories", category_id, "mark-all-as-read").put();
  }

  async opmlExport() {
    return await this.endpoint("export").returnRawResponseObject().get();
  }

  async opmlImport(xml) {
    return await this.endpoint("import").bodyRaw(xml).contentType("text/xml").post();
  }

  async createUser(
    username, 
    password, 
    is_admin = false, 
    google_id = undefined, 
    openid_connect_id = undefined
  ) {
    return await this.endpoint("users").body({ 
      username, 
      password, 
      is_admin,
      ...(google_id ?? { google_id }),
      ...(openid_connect_id ?? { openid_connect_id }),
    }).post();
  }

  async updateUser(
    user_id,
    username = undefined,
    password = undefined,
    theme = undefined,
    language = undefined,
    timezone = undefined,
    entry_sorting_direction = undefined,
    stylesheet = undefined,
    google_id = undefined,
    openid_connect_id = undefined,
    entries_per_page = undefined,
    is_admin = undefined,
    keyboard_shortcuts = undefined,
    show_reading_time = undefined,
    entry_swipe = undefined,
  ) {
    return await this.endpoint("users", user_id).body({
      ...(username ?? { username }),
      ...(password ?? { password }),
      ...(theme ?? { theme }),
      ...(language ?? { language }),
      ...(timezone ?? { timezone }),
      ...(entry_sorting_direction ?? { entry_sorting_direction }),
      ...(stylesheet ?? { stylesheet }),
      ...(google_id ?? { google_id }),
      ...(openid_connect_id ?? { openid_connect_id }),
      ...(entries_per_page ?? { entries_per_page }),
      ...(is_admin ?? { is_admin }),
      ...(keyboard_shortcuts ?? { keyboard_shortcuts }),
      ...(show_reading_time ?? { show_reading_time }),
      ...(entry_swipe ?? { entry_swipe }),
    }).put();
  }

  async getCurrentUser() {
    return await this.endpoint("me").get();
  }

  async getUser(user_id_or_username) {
    return await this.endpoint("users", user_id_or_username).get();
  }

  async getUsers() {
    return await this.endpoint("users").get();
  }

  async deleteUser(user_id) {
    return await this.endpoint("users", user_id).del();
  }

  async markUserEntriesAsRead(user_id) {
    return await this.endpoint("users", user_id, "mark-all-as-read").put();
  }

  async fetchReadUnreadCounters() {
    return await this.endpoint("feeds", "counters").get();
  }

  async healthCheck() {
    return await this.endpoint("healthcheck").get();
  }

  async version() {
    return await this.endpoint("version").get();
  }
}
