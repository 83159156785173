import * as React from "react";
import { useCallback, useLayoutEffect } from "react";
import { FaSync } from "react-icons/fa";
import { Link } from "react-router-dom";
import ca from "classnames";
import useStore from "./store";
import { backendUrl } from "./config";
import { getCalculatedCountersFromFeedCounters } from './store/transform';
import { categoryList } from "./Routes";

export default function Header() {
  const feeds = useStore(state => state.feeds);
  const categories = useStore(state => state.categories);
  const counters = useStore(state => state.counters);

  const requestsInProgress = useStore((state) => state.requestsInProgress);
  const readItems = useStore((state) => state.readItems);
  const setApiKey = useStore((state) => state.setApiKey);

  const { unreadCount } = getCalculatedCountersFromFeedCounters(counters, categories, feeds);

  const logout = useCallback(() => {
    setApiKey(undefined);
  }, [setApiKey]);

  useLayoutEffect(() => {
    document.title = `${
      unreadCount > 0 ? `(${unreadCount}) ` : ""
    }Responsive reader`;
  }, [unreadCount]);

  const networkActivityClassNames = ca({
    spin: true,
    hidden: !requestsInProgress,
  });

  return (
    <header className="sticky ontop bg-gray pd-small flex justify-space-between align-center mg-bottom-small">
      <div className="pd-left-small bold">
        <Link to={categoryList()}>Responsive reader</Link> ({unreadCount - (readItems?.size || 0)})
        <a href={backendUrl} target="_blank" rel="noreferrer"> miniflux</a>
        <button type="button" className="mg-left-medium" onClick={logout}>
          Logout
        </button>
      </div>
      <div className="pd-right-small">
        <FaSync className={networkActivityClassNames} size={20} />
      </div>
    </header>
  );
}
