import * as React from 'react';
import { useCallback } from 'react';
import { useAutoRefresh, useIsOllamaAvailableResult, useOllamaAutoSummaryState } from './hooks';
import Category from './Category';
import useStore from './store';
import { getCalculatedCountersFromFeedCounters } from './store/transform';

export default function CategoryList() {
  const feeds = useStore(state => state.feeds);
  const categories = useStore(state => state.categories);
  const counters = useStore(state => state.counters);
  const loadFeeds = useStore(state => state.loadFeeds);
  const markAllAsRead = useStore(state => state.markAllAsRead);
  const updateCounters = useStore(state => state.updateCounters);
  const isOllamaAvailable = useIsOllamaAvailableResult();
  const {
    isOllamaAutoSummaryEnabled,
    toggleOllamaAutoSummaryEnabled
  } = useOllamaAutoSummaryState();
  
  const { categoryCounters } = getCalculatedCountersFromFeedCounters(counters, categories, feeds);

  useAutoRefresh(loadFeeds, 10000, 60000);
  useAutoRefresh(updateCounters, 10000, 60000);

  const hasUnread = categories?.filter?.(c => categoryCounters?.unread?.[c.id] > 0);
  const noUnread = categories?.filter?.(c => !categoryCounters?.unread?.[c.id]);

  const forceReload = useCallback(() => {
    window.location.reload(true);
  }, []);

  return (
    <div>
      <div className="actions">
        <button type="button" onClick={markAllAsRead}>Mark all feeds as read</button>
        <button type="button" onClick={forceReload}>Force reload</button>
        {isOllamaAvailable && <button type="button" onClick={toggleOllamaAutoSummaryEnabled}>
          {isOllamaAutoSummaryEnabled ? "Disable auto summary" : "Enable auto summary"}
        </button>}
      </div>
      <ul className="list-style-none pd-none pd-top-medium mg-none">
        {hasUnread?.map?.(c => (
          <Category key={c.id} id={c.id} title={c.title} unread={categoryCounters?.unread?.[c.id] ?? 0} />
        ))}
        {noUnread?.map?.(c => (
          <Category key={c.id} id={c.id} title={c.title} unread={categoryCounters?.unread?.[c.id] ?? 0} />
        ))}
      </ul>
    </div>
  );
}
