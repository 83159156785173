import * as React from "react";
import { useEffect, useCallback, useState } from "react";
import Logo from "./Logo";
import useStore from "./store";

export default function LoginGuard({ children }) {
  const [inputApiKey, setInputApiKey] = useState("");

  const api_key = useStore((state) => state.api_key);
  const setApiKey = useStore((state) => state.setApiKey);

  const onSubmit = useCallback(
    (e) => {
      e?.preventDefault?.();
      if (inputApiKey) {
        setApiKey(inputApiKey);
      }
    },
    [setApiKey, inputApiKey]
  );

  const onChange = useCallback(
    (e) => {
      if (e.target.id === "apiKey") setInputApiKey(e.target.value);
    },
    [setInputApiKey]
  );

  useEffect(() => {
    // Auto login
    if (!api_key && localStorage["api_key"]) {
      setApiKey(localStorage["api_key"]);
    }
  }, [api_key, setApiKey]);

  if (!api_key) {
    return (
      <form
        id="login"
        onSubmit={onSubmit}
        className="flex flex-column justify-center align-center h-100"
      >
        <div className="logo" alt="Logo">
          <Logo />
        </div>
        <div className="flex flex-column justify-center align-center bg-gray pd-medium">
          <div className="pd-medium">
            <label className="db" htmlFor="apiKey">
              Api key:
            </label>
            <input
              autoFocus
              id="apiKey"
              type="text"
              onChange={onChange}
              value={inputApiKey}
            ></input>
          </div>
          <div className="pd-medium">
            <button
              disabled={!inputApiKey}
              onClick={onSubmit}
              className="pd-medium mg-top-medium"
            >
              Login
            </button>
          </div>
        </div>
      </form>
    );
  }

  return children;
}
