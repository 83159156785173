export default function Attachments({ item }) {
  if (!item.attachments?.length) return null;
  return (
    <>
      {item.attachments.map((a) => (
        <Attachment key={a.content_url} item={a} link={item.link} />
      ))}
    </>
  );
}

function Attachment({ item, link }) {
  const [contentCategory] = item.content_type?.split("/", 2);
  if (!contentCategory) return null;
  const Handler = attachmentHandlers[contentCategory];
  if (!Handler) return null;
  return <Handler item={item} link={link} />;
}

const attachmentHandlers = {
  img: ImageAttachment,
  image: ImageAttachment,
  video: VideoAttachment,
  audio: AudioAttachment,
};

function ImageAttachment({ item, link }) {
  return (
    <a className="img-attachment" href={link} target="_blank" rel="noreferrer">
      <img alt={item.content_url} src={item.content_url} loading="lazy" />
    </a>
  );
}

function VideoAttachment({ item }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={item.content_url}
    >{`Video: ${item.content_url}`}</a>
  );
}

function AudioAttachment({ item }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={item.content_url}
    >{`Audio: ${item.content_url}`}</a>
  );
}