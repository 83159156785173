import * as React from 'react';
import { Link } from 'react-router-dom';
import { feedHeadlineList } from './Routes';

export default function Feed({
  id,
  title,
  unread
}) {
  return (
    <li className="pd-none pd-top-medium pd-left-medium">
      <div className="flex justify-space-between">
        <div><Link to={feedHeadlineList(id)}>{title}</Link></div>
        <div>{unread}</div>
      </div>
    </li>
  );
}
