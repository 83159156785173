import React from "react";
import parse, { domToReact, attributesToProps } from "html-react-parser";
import TwitchEmbed from "./components/TwitchEmbed";
import YoutubeEmbed from "./components/YoutubeEmbed";
import LazyVideo from "./components/LazyVideo";

const contentReplace = (item) => {
  const {
    replaceFunction
  } = contentReplaceInner(item);

  const content = parse(item.content, {
    replace: replaceFunction,
    trim: true,
  });

  return content;
};

const contentReplaceInner = (item) => {
  const replaceState = {
    embeddedVideos: new Set()
  };
  
  const { url } = item;
  const urlParsed = new URL(url);
  const isYoutube = !!url?.match(
    /https:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)(?<id>[^&]*).*$/
  )?.groups?.["id"];
  
  const replaceFunction = (domNode) => {
    if (domNode.type === "tag" && domNode.name === "video") {
      return handleVideo(domNode, replaceState);
    }
    if (domNode.type === "tag" && domNode.name === "a") {
      return handleAnchor(domNode, replaceState);
    }
    if (domNode.type === "tag" && domNode.name === "img") {
      return handleImg(domNode, item);
    }
    if (domNode?.attribs?.style || domNode?.attribs?.contentEditable) {
      const { style, contentEditable, ...rest } = domNode.attribs;
      return React.createElement(
        domNode.name,
        attributesToProps(rest),
        domNode.children && domToReact(domNode.children)
      );
    }
    if (
      domNode.type === "tag" &&
      domNode.name === "table" &&
      url?.match(/(www|old)\.reddit\.com/)
    ) {
      const tbody = domNode.children.find((d) => d.name === "tbody");
      const tr = tbody?.children.find((d) => d.name === "tr");
      const td = tr?.children?.filter((d) => d.name === "td")?.[1];

      if (td && td.children?.length) {
        return (
          <>
            {domToReact(td.children, {
              replace: replaceFunction,
              trim: true,
            })}
          </>
        );
      }
    }
    if (domNode.type === "tag" && domNode.name === "iframe" && isYoutube) {
      // Remove all iframes if it's a youtube video. Leads to double embeds.
      return <React.Fragment />;
    }
    if (domNode.type === "tag" && domNode.name === "iframe" && domNode.attribs?.src?.match(/youtube.*?autoplay/)) {
      // Fucking autoplay youtube embeds on eurogamer
      const {
        src,
        ...rest
      } = domNode.attribs;
      const srcUrl = new URL(src);
      srcUrl.searchParams.delete("autoplay");
      return React.createElement(
        domNode.name,
        attributesToProps({ src: srcUrl.toString(), ...rest }),
        domToReact(domNode.children)
      );
    }
    if (domNode.type === "tag" && domNode.name === "figure" && urlParsed.host.match(/nrk\.no$/)) {
      // Handle broken implementation of alternative video sources for NRK articles.
      const video = domNode.children.find(
        (d) => d.type === "tag" && 
               d.name === "video" && 
               !!d.children.find(
                (s) => s.type === "tag" && 
                       s.name === "source" && 
                       s.attribs.type === "video/mp4" && 
                       s.attribs.src.match(/_1080\.mp4$/)
               )
      );
      if (video) {
        return React.createElement(
          video.name, 
          attributesToProps(video.attribs),
          domToReact(video.children)
        );
      }
    }
    return domNode;
  }

  return {
    replaceFunction,
    replaceState
  };
};

function handleVideo(domNode, replaceState) {
  const source = domNode?.children?.[0];
  const src = source?.attribs?.src;
  const alreadyEmbedded = replaceState.embeddedVideos.has(src);

  if (alreadyEmbedded) {
    return null;
  }
  
  replaceState.embeddedVideos.add(src);

  // Special handling for Twitch.. Turn the video tag into an iframe instead.
  if (source?.attribs?.src?.match(/clips\.twitch\.tv\//)) {
    return <TwitchEmbed src={source.attribs.src} />;
  }

  // By default make the video tag "lazy"
  const autoPlay = false;
  return (
    <LazyVideo
      {...attributesToProps(domNode.attribs)}
      autoPlay={autoPlay}
      muted={autoPlay}
    >
      {domToReact(domNode.children)}
    </LazyVideo>
  );
}

function handleAnchor(domNode, replaceState) {
  const href = domNode?.attribs?.href;
  const twitchClip = href?.match(
    /((?:m\.|clips\.)twitch\.tv\/)|(twitch\.tv\/.*?\/clip\/)/
  );
  if (!replaceState.embeddedVideos.has(href) && twitchClip) {
    replaceState.embeddedVideos.add(href);
    return <TwitchEmbed src={domNode.attribs.href} />;
  }
  let ytid = href?.match(
    /https:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)(?<id>[^&]*).*$/
  )?.groups?.["id"];
  if (!replaceState.embeddedVideos.has(href) && ytid) {
    replaceState.embeddedVideos.add(href);
    return <YoutubeEmbed ytid={ytid} />;
  }
  if (href.match(/go\.redirectingat\.com/)) {
    const url = new URL(href);
    const realUrl = url.searchParams.get("url");
    return (
      <a 
        {...attributesToProps(domNode.attribs)} 
        href={realUrl}>
        {domToReact(domNode.children)}
      </a>
    );
  }
  return domNode;
}

function handleImg(domNode, item) {
  const alt = !!domNode?.attribs?.alt ? {} : { alt: item.title };
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...attributesToProps(domNode.attribs)}
      {...alt}
      src={new URL(domNode.attribs.src, item.url).toString()}
      loading="lazy"
    />
  );
}

export default contentReplace;
