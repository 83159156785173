import * as React from "react";
import { Route, Routes } from "react-router-dom";
import CategoryList from "./CategoryList";
import ErrorApi from "./ErrorApi";
import { CategoryHeadlineList, FeedHeadlineList } from "./HeadlineList";

export default function RoutesComponent() {
  return (
    <>
      <ErrorApi />
      <Routes>
        <Route path="/" element={<CategoryList />} />
        <Route path="/feed/:id" element={<FeedHeadlineList />} />
        <Route path="/category/:id" element={<CategoryHeadlineList />} />
      </Routes>
    </>
  );
}

export function categoryList() {
  return "/";
}

export function settings() {
  return "/settings";
}

export function feedHeadlineList(id) {
  return `/feed/${id}`;
}

export function categoryHeadlineList(id) {
  return `/category/${id}`;
}
