import React from "react";
import cn from "classnames";
import { Waypoint } from "react-waypoint";
import { useCallback, useState } from "react";
import useStore from "./store";

import YoutubeEmbed from "./components/YoutubeEmbed";
import Star from './components/Star';
import Attachments from "./components/Attachments";

import contentReplace from "./contentReplace";
import { useOllamaAutoSummaryState, useOllamaHeadlineSummary } from "./hooks";

export default function Headline({ item }) {
  const title = item.title;
  const content = contentReplace(item);
  const showAttachments = !item?.url.match(/(www|old)\.reddit\.com/);

  const readItems = useStore((state) => state.readItems);
  const updateArticleAsRead = useStore((state) => state.updateArticleAsRead);
  const setItemStarred = useStore((state) => state.setItemStarred);

  const unread = !readItems.has(item.id);

  const markAsRead = useCallback(() => {
    if (unread) updateArticleAsRead(item.id);
  }, [updateArticleAsRead, item.id, unread]);

  const toggleStarItem = useCallback(() => {
    setItemStarred(item.id, !item.starred);
  }, [setItemStarred, item.id, item.starred]);

  const [expanded, setExpanded] = useState(false);
  const expand = useCallback(() => {
    setExpanded(true);
  }, []);

  const classes = cn(
    {
      "bg-gray": unread,
      expanded: expanded,
    },
    "pd-medium",
    "mg-bottom-medium"
  );

  const ytlink = item?.url.match(
    /https:\/\/(?:www\.)?youtube\.com\/watch\?v=(?<id>[^&]*).*$/
  )?.groups?.["id"];

  let timestamp = new Date(item.published_at).toLocaleString();

  const {
    isOllamaAvailable,
    summary,
    requestSummary,
    summaryRequested
  } = useOllamaHeadlineSummary();

  const summarize = useCallback(() => {
    isOllamaAvailable && requestSummary(title, item.feed.title, item.content);
  }, [isOllamaAvailable, requestSummary, item, title]);

  const {
    isOllamaAutoSummaryEnabled
  } = useOllamaAutoSummaryState();

  const autoSummarize = useCallback(() => {
    if (isOllamaAvailable && isOllamaAutoSummaryEnabled && !summaryRequested) {
      summarize();
    }
  }, [isOllamaAvailable, isOllamaAutoSummaryEnabled, summaryRequested, summarize]);

  return (
    <li>
      <Waypoint onEnter={autoSummarize} />
      <article className={classes}>
        <h3>
          <Star starred={item.starred} onClick={toggleStarItem} />
          <a target="_blank" rel="noreferrer" href={item.url}>
            {title}
          </a>
          {!expanded && (
            <button type="button" className="expand" onClick={expand}>
              Expand
            </button>
          )}
          {isOllamaAvailable && !summaryRequested && (
            <button type="button" className="expand" onClick={summarize}>
              Summarize
            </button>
          )}
        </h3>
        {isOllamaAvailable && summary && (
          <section className="llmsummary">{summary}</section>
        )}
        <h6>{`${item.feed.title}${item.author ? `, ${item.author}` : ""}`}</h6>
        <h6>{timestamp}</h6>
        <section>
          {showAttachments && !!item.attachments?.length && (
            <Attachments item={item} />
          )}
          {!!ytlink && <YoutubeEmbed ytid={ytlink} />}
        </section>
        <section>{content}</section>
      </article>
      <Waypoint onEnter={markAsRead} />
    </li>
  );
}
