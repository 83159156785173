import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAutoRefresh } from "./hooks";
import Headline from "./Headline";
import { useCallback } from "react";
import { MdCheck } from "react-icons/md";
import { Waypoint } from "react-waypoint";
import useStore from "./store";

function HeadlineList({ isCat, id }) {
  const headlines = useStore((state) => state.headlines)[
    isCat ? "categories" : "feeds"
  ][id];
  const readItems = useStore((state) => state.readItems);
  const loadHeadlines = useStore((state) => state.loadHeadlines);
  const loadMoreHeadlines = useStore((state) => state.loadMoreHeadlines);
  const updateCounters = useStore((state) => state.updateCounters);
  const markArticlesAsReadAndReload = useStore(
    (state) => state.markArticlesAsReadAndReload
  );
  const clearArticlesRead = useStore((state) => state.clearArticlesRead);
  const clearHeadlines = useStore((state) => state.clearHeadlines);

  useAutoRefresh(updateCounters, 30000, 60000);

  const navigate = useNavigate();

  useEffect(() => {
    loadHeadlines(id, isCat);
    return () => {
      clearHeadlines(id, isCat);
      clearArticlesRead();
    };
  }, [clearArticlesRead, clearHeadlines, loadHeadlines, isCat, id]);

  const markAsReadAndReload = useCallback(() => {
    markArticlesAsReadAndReload(id, isCat, () => navigate("/"));
  }, [markArticlesAsReadAndReload, id, isCat, navigate]);

  const loadMore = useCallback(() => {
    loadMoreHeadlines(id, isCat);
  }, [loadMoreHeadlines, id, isCat]);

  useEffect(() => {
    function onKeyUp(e) {
      if (e.key === " " || e.code === "space" || e.keyCode === 32) {
        e.preventDefault?.();
        e.stopPropagation?.();
        markAsReadAndReload();
      }
    }
    function onKeyDown(e) {
      if (e.key === " " || e.code === "space" || e.keyCode === 32) {
        // block spacebar scrolling
        e.preventDefault?.();
        e.stopPropagation?.();
      }
    }
    document.body.addEventListener("keyup", onKeyUp);
    document.body.addEventListener("keydown", onKeyDown);
    return () => {
      document.body.removeEventListener("keyup", onKeyUp);
      document.body.removeEventListener("keydown", onKeyDown);
    };
  }, [markAsReadAndReload]);

  return (
    <>
      {!!headlines?.length && (
        <ul className="list-style-none pd-none pd-top-medium pd-bottom-xl">
          {headlines?.map((h) => (
            <Headline key={h.url} item={h} />
          ))}
          <Waypoint onEnter={loadMore} />
        </ul>
      )}
      {!headlines?.length && <div>No more articles here</div>}
      {!!readItems?.size && (
        <button
          title={`Mark ${readItems?.size} as read`}
          className="fixed bottom left width-100 bd-btn fnt-bigger bg-gray fg-color pd-medium flex align-center justify-center"
          onClick={markAsReadAndReload}
        >
          <span>
            {readItems?.size} / {headlines?.length}
          </span>{" "}
          <MdCheck size={28} />
        </button>
      )}
    </>
  );
}

export const CategoryHeadlineList = () => {
  const { id } = useParams();
  return <HeadlineList isCat={true} id={id} />;
};

export const FeedHeadlineList = () => {
  const { id } = useParams();
  return <HeadlineList isCat={false} id={id} />;
};
