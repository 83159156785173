import * as React from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import FeedList from './FeedList';
import { categoryHeadlineList } from './Routes';
import useStore from './store';

export default function Category({
  id,
  title,
  unread
}) {
  const collapseCategory = useStore(state => state.collapseCategory);
  const expandCategory = useStore(state => state.expandCategory);
  const expandedCategories = useStore(state => state.expandedCategories);
  const toggleNotifications = useStore(state => state.toggleNotifications);
  const isExpanded = expandedCategories.some(e => e === id);

  const toggleExpanded = useCallback((e) => {
    e?.preventDefault?.();
    isExpanded && collapseCategory(id);
    !isExpanded && expandCategory(id);
  }, [id, isExpanded, collapseCategory, expandCategory]);

  const toggleNotify = useCallback((e) => {
    e?.preventDefault?.();
    toggleNotifications(id);
  }, [id, toggleNotifications]);

  const linkClasses = cn({
    'normal': !unread
  });

  return (
    <li className="pd-medium mg-bottom-medium">
      <div className="flex justify-space-between">
        <div>
          <button onClick={toggleExpanded} className="mg-right-medium">{isExpanded ? '-' : '+'}</button>
          <button onClick={toggleNotify} className="mg-right-medium">N</button>
          <Link className={linkClasses} to={categoryHeadlineList(id)}>{title}</Link>
        </div>
        <div>{unread}</div>
      </div>
      {isExpanded && <FeedList cat_id={id} />}
    </li>
  );
};
