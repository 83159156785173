import { useEffect } from "react";
import useStore from "./store";

export default function Notifications() {
  const notificationCategories = useStore(
    (state) => state?.notificationCategories
  );
  return (
    <>
      {notificationCategories ? Array.from(notificationCategories).map(c => <NotificationCatgory key={c} id={c} />) : null}
    </>
  );
}

function NotificationCatgory({ id }) {
  const category = useStore(
    (state) => state.categories.find(c => c.id === id)
  );
  const categoryCounters = useStore(
    (state) => state.categoryCounters
  );
  const title = category?.title ?? "";
  const unread = categoryCounters?.unread?.[id];
  useEffect(() => {
    if (unread > 0 && title) {
      new Notification(`${unread} messages in ${title}`);
    }
  }, [unread, title]);
  return null;
}
