import * as React from "react";
import Feed from "./Feed";
import useStore from "./store";

export default function FeedList({ cat_id }) {
  const counters = useStore(state => state.counters);
  const feeds = useStore(state => state.feeds).filter(f => f.category.id === cat_id);

  return feeds?.length ? (
    <ul className="list-style-none pd-none pd-left-large">
      {feeds.map((f) => (
        <Feed key={f.id} id={f.id} title={f.title} unread={counters?.unread?.[f.id] ?? 0} />
      ))}
    </ul>
  ) : null;
}
