import { create } from "zustand";
import { produce, enableMapSet } from "immer";
import ApiClient from "../api";
import { backendUrl } from "../config";

enableMapSet();

const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api);

const useStore = create(
  immer((set, get) => ({
    client: new ApiClient(
      backendUrl,
      () => get().api_key,
      () => get().setApiKey(undefined)
    ),
    requestsInProgress: 0,

    api_key: undefined,

    apiCallError: undefined,
    counters: {
      read: {},
      unread: {},
    },
    unreadCount: 0,
    categories: [],
    feeds: [],
    expandedCategories: [],
    headlines: {
      categories: {},
      feeds: {},
    },
    readItems: new Set(),

    notificationCategories: new Set(),

    ollamaAvailable: false,
    ollamaAutoSummary: false,
    setOllamaAvailable: () => set(state => ({ ollamaAvailable: true })),
    setOllamaAutoSummary: (value) => set(state => ({ ollamaAutoSummary: value })),

    // network
    increaseRequestsInProgress: () =>
      set((state) => ({ requestsInProgress: state.requestsInProgress + 1 })),
    decreaseRequestsInProgress: () =>
      set((state) => ({ requestsInProgress: state.requestsInProgress - 1 })),

    // session
    setApiKey(api_key) {
      if (api_key === undefined) {
        localStorage.removeItem("api_key");
      }
      else {
        localStorage.setItem("api_key", api_key);
      }
      set(() => ({ api_key }));
    },

    // data
    setCounters: (counters) =>
      set(() => ({
        counters: {
          read: counters.reads,
          unread: counters.unreads,
        },
      })),
    setCategories: (categories) =>
      set(() => ({ categories })),
    setFeeds: (feeds) =>
      set(() => ({ feeds })),
    expandCategory: (categoryId) =>
      set((state) => ({
        expandedCategories: [...state.expandedCategories, categoryId],
      })),
    collapseCategory: (categoryId) =>
      set((state) => ({
        expandedCategories: state.expandedCategories.filter(
          (c) => c !== categoryId
        ),
      })),
    setHeadlines: (id, is_cat, headlines) =>
      set((state) => {
        const collection = state.headlines[is_cat ? "categories" : "feeds"];
        collection[id] = headlines;
      }),
    clearHeadlines: (id, is_cat) =>
      set((state) => {
        const collection = state.headlines[is_cat ? "categories" : "feeds"];
        delete collection[id];
      }),
    updateArticleAsRead: (itemId) =>
      set((state) => {
        state.readItems.add(itemId);
      }),
    clearArticlesRead: () =>
      set((state) => {
        state.readItems.clear();
      }),
    updateArticleStarred: (id, starred) =>
      set((state) => {
        for (const key of Object.keys(state.headlines)) {
          for (const collection of Object.keys(state.headlines[key])) {
            const article = state.headlines[key][collection].find(
              (i) => i.id === id
            );
            if (article) article.starred = starred;
          }
        }
      }),
    setApiCallError: (error) =>
      set((state) => {
        state.apiCallError = error;
      }),

    // api calls
    call: async (action) => {
      const client = get().client;
      try {
        get().increaseRequestsInProgress();
        const result = await action(client);
        return result;
      } finally {
        get().decreaseRequestsInProgress();
      }
    },
    updateCounters: async () => {
      const counters = await get().call((client) => 
        client.fetchReadUnreadCounters()
      );
      get().setCounters(counters);
    },
    loadFeeds: async () => {
      const feeds = await get().call((client) =>
        client.getFeeds()
      );
      feeds.sort((a, b) => a?.title?.localeCompare(b?.title));
      const categories = [];
      for (const feed of feeds) {
        if (!categories.find((c) => c.id === feed.category.id)) {
          categories.push(feed.category);
        }
      }
      categories.sort((a, b) => a?.title?.localeCompare(b?.title));
      
      get().setFeeds(feeds);
      get().setCategories(categories);
    },
    loadHeadlines: async (id, is_cat) => {
      const headlines = await get().call((client) => {
        if (is_cat) {
          return client.getCategoryEntries(id, 0, 200, "unread", "published_at", "desc");
        }
        else {
          return client.getFeedEntries(id, 0, 200, "unread", "published_at", "desc");
        }
      });
      get().setHeadlines(id, is_cat, headlines?.entries ?? []);
    },
    loadMoreHeadlines: async (id, is_cat) => {
      const currentHeadlines =
        get().headlines[is_cat ? "categories" : "feeds"][id];
      const headlines = await get().call((client) => {
        if (is_cat) {
          return client.getCategoryEntries(id, currentHeadlines?.length, 200, "unread", "published_at", "desc");
        }
        else {
          return client.getFeedEntries(id, currentHeadlines?.length, 200, "unread", "published_at", "desc");
        }
      });
      get().setHeadlines(id, is_cat, [
        ...(currentHeadlines ?? []),
        ...(headlines?.entries ?? []).filter(
          (h) => !currentHeadlines.some((h2) => h.id === h2.id)
        ),
      ]);
    },
    markArticlesAsReadAndReload: async (id, is_cat, navigateToFrontPage) => {
      const articleIds = Array.from(get().readItems);
      try {
        await get().call((client) =>
          client.updateEntries(articleIds, "read")
        );
        get().clearArticlesRead();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        navigateToFrontPage();
      }
      catch {}
    },
    setItemStarred: async (id, starred) => {
      try {
        await get().call((client) =>
          client.toggleEntryBookmark(id)
        );
        get().updateArticleStarred(id, starred);
      }
      catch {}
    },
    markAllAsRead: async () => {
      const result = window.confirm("Mark all articles as read?");
      if (result) {
        const categories = get().categories;
        for (const c of categories) {
          const result = await get().call((client) =>
            client.catchupFeed(c.id, true)
          );
          if (result?.status !== "OK") {
            console.log(`Failed to mark category ${c.id} as read`, result);
          }
        }
      }
    },

    // notifications
    toggleNotifications: async (categoryId) => {
      if (Notification.permission !== "granted") {
        await Notification.requestPermission();
      }
      set((state) => {
        if (state.notificationCategories.has(categoryId)) {
          state.notificationCategories.delete(categoryId);
          new Notification("Notifications disabled!");
        } else {
          state.notificationCategories.add(categoryId);
          new Notification("Notifications enabled!");
        }
      });
    },
  }))
);

window._store = useStore;

export default useStore;
