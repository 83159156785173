export function getCalculatedCountersFromFeedCounters(counters, categories, feeds) {
  return {
    categoryCounters: {
      read: categories?.reduce((acc, current) => {
        acc[current.id] = feeds?.reduce((acc2, f) => {
          if (f.category.id === current.id) {
            return acc2 + (counters?.read?.[f.id] ?? 0);
          }
          return acc2;
        }, 0);
        return acc;
      }, {}),
      unread: categories?.reduce((acc, current) => {
        acc[current.id] = feeds?.reduce((acc2, f) => {
          if (f.category.id === current.id) {
            return acc2 + (counters?.unread?.[f.id] ?? 0);
          }
          return acc2;
        }, 0);
        return acc;
      }, {})
    },
    unreadCount: 
      Object.keys(counters?.unread ?? {})
            .reduce((acc, current) => acc + counters?.unread?.[current], 0),
  };
}
