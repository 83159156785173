import React from "react";
import Header from "./Header";
import Notifications from "./Notifications";
import Routes from "./Routes";
import { useAppUpdateChecker, useOllamaAvailabilityCheck } from "./hooks";

function App() {
  useAppUpdateChecker();
  useOllamaAvailabilityCheck();
  
  return (
    <>
      <Notifications />
      <Header />
      <div className="pd-none mg-none">
        <Routes />
      </div>
    </>
  );
}

export default App;
