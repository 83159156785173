import * as React from "react";
import { Waypoint } from "react-waypoint";
import { useCallback, useState } from "react";

const localOrigin = encodeURIComponent("http://localhost:3000");
const serverOrigin = encodeURIComponent("https://reader.kivle.com");

export default function YoutubeEmbed({ ytid }) {
  const [visible, setVisible] = useState(false);
  const onEnter = useCallback(() => {
    setVisible(true);
  }, []);

  const iframeSrc = `https://www.youtube.com/embed/${ytid}?origin=${localOrigin}&origin=${serverOrigin}`;
  return visible ? (
    <iframe
      className="video-clip"
      title={iframeSrc}
      src={iframeSrc}
      allow="fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; accelerometer;"
      allowFullScreen
    />
  ) : (
    <>
      <Waypoint topOffset={-1000} onEnter={onEnter} />
      <div className="video-clip" />
    </>
  );
}
