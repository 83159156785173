import * as React from "react";
import { Waypoint } from "react-waypoint";
import { useCallback, useState } from "react";

export default function TwitchEmbed({ src }) {
  // https://m.twitch.tv/clip/SwissCourteousBurritoTakeNRG-hVnpYsy4s4jbiBmp
  // https://clips.twitch.tv/SmoggySincereSpiderNononoCat-lWDsfKP6Hup6F-3A
  const url = new URL(src);
  if (url.pathname !== "/embed") {
    if (url.hostname === "m.twitch.tv" && url.pathname.match(/^\/clip\//)) {
      // http://m.twitch.tv/clip/xyz
      url.hostname = "clips.twitch.tv";
      const clipid = url.pathname.replace("/clip/", "");
      url.searchParams.append("clip", clipid);
      url.pathname = "/embed";
    } else if (
      url.hostname === "www.twitch.tv" ||
      url.hostname === "twitch.tv"
    ) {
      // http://www.twitch.tv/channelname/clip/xyz
      url.hostname = "clips.twitch.tv";
      const path = url.pathname.split("/");
      const clipid = path[3];
      url.searchParams.append("clip", clipid);
      url.pathname = "/embed";
    } else {
      // http://clips.twitch.tv/xyz
      const clipid = url.pathname.replace("/", "");
      url.searchParams.append("clip", clipid);
      url.pathname = "/embed";
    }
  }
  url.searchParams.append("parent", "localhost");
  url.searchParams.append("parent", "reader.kivle.com");
  const iframeSrc = url.toString();
  const [visible, setVisible] = useState(false);
  const onEnter = useCallback(() => {
    setVisible(true);
  }, []);

  return visible ? (
    <iframe
      className="video-clip"
      title={iframeSrc}
      src={iframeSrc}
      allow="fullscreen;"
      allowFullScreen
    />
  ) : (
    <>
      <Waypoint topOffset={-1000} onEnter={onEnter} />
      <div className="video-clip" />
    </>
  );
}
