import { AiFillStar, AiOutlineStar } from "react-icons/ai";

export default function Star({ onClick, starred }) {
  const icon = starred ? <AiFillStar size={20} /> : <AiOutlineStar size={20} />;

  return (
    <button className="btn-reset" onClick={onClick}>
      {icon}
    </button>
  );
}