import * as React from 'react';
import useStore from './store';

export default function ErrorApi() {
  const apiCallError = useStore(state => state.apiCallError);

  return apiCallError ?
    <div>
      <h1>Api Call Error</h1>
      <p>{apiCallError}</p>
    </div> : null;
}
